import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import style from "../styles/about.module.css"

const privacy = () => {
  return (
    <Layout>
      <SEO title="about" />

      <h1>DATENSCHUTZ</h1>
      <p className={style.p}>
        Der Schutz Ihrer persönlichen Daten ist mir ein besonderes Anliegen. Ich
        verarbeite Daten daher ausschließlich auf Grundlage der gesetzlichen
        Bestimmungen (DSGVO, TKG 2003). Nachfolgend möchte ich Sie über die
        Verarbeitung personenbezogener Daten informieren, soweit diese im Rahmen
        meines Internetangebotes erfolgt. Am Ende des Dokumentes möchte ich Sie
        weiter darüber informieren, welche datenschutzrelevanten Tools von
        Drittanbietern von uns auf der Webseite eingesetzt werden. Bedenken Sie,
        dass die Datenübertragung im Internet grundsätzlich mit
        Sicherheitslücken bedacht sein kann. Ein vollumfänglicher Schutz vor dem
        Zugriff durch Fremde ist nicht realisierbar.
      </p>

      <p>
        <strong>
          Wenn Sie per Formular auf der Website oder per E-Mail mit mir Kontakt
          aufnehmen, werden die angegebenen Daten zwecks Bearbeitung der Anfrage
          und für den Fall von Anschlussfragen im System der Website bzw. im
          E-Mail-Programm gespeichert. Die Daten gebe ich nicht ohne Ihre
          Einwilligung an Dritte weiter.
        </strong>
      </p>
      <p>
        Mit dieser Datenschutzerklärung informiere ich gemäß Artikel 13/14
        DSGVO, welche personenbezogene Daten von betroffenen Personen
        verarbeitet werden, wobei nur solche personenbezogenen Daten erhoben
        werden, die für die Durchführung und Abwicklung meiner Leistungen
        erforderlich sind oder die Sie uns freiwillig zur Verfügung gestellt
        haben. Als personenbezogene Daten gelten sämtliche Informationen, welche
        dazu dienen, eine Person zu bestimmen und welche zu Ihnen zurückverfolgt
        werden können – also beispielsweise Name, E-Mail-Adresse und
        Telefonnummer.
      </p>
      <h2>1. Wann verarbeiten wir welche personenbezogenen Daten</h2>
      <p>
        In folgenden Fällen verarbeite ich personenbezogenen Daten: Nutzung des
        Kontaktformulars der Webseite: Um Ihnen die Funktionalität des
        Kontaktformulars zur Verfügung zu stellen, werden die von Ihnen
        angegebene Daten an Formspree Inc. gesendet - sobald sie das
        Kontaktformular absenden. Diese Daten werden dort verarbeitet,
        gespeichert und an mich per E-Mail weitergegeben. Außerdem werden von
        Formspree unter anderem weitere Daten wie Ihre IP-Adresse, Ihr Typ des
        Browsers, die Domain der Webseite, das Datum und die Zeit des Zugriffs
        erhoben, um die gewünschte Funktionalität des Kontaktformulars
        bereitzustellen. Eine detaillierte Übersicht der erhobenen Daten finden
        Sie hier
        <a href="http://docs.formspree.io/files/terms.pdf">
          http://docs.formspree.io/files/terms.pdf
        </a>
        Rechtsgrundlage für die Nutzung von Formspree ist Art. 6 Abs. 1 S. 1
        lit. f DS-GVO (berechtigtes Interesse). In dem vorliegenden Fall werden
        personenbezogene Daten in die USA übertragen werden: Die Verarbeitung,
        Weitergabe und Speicherung der Daten ist von Formspree in dem folgenden
        Dokument zusammengefasst:
        <a href="http://docs.formspree.io/files/terms.pdf">
          http://docs.formspree.io/files/terms.pdf
        </a>
      </p>

      <p>
        Besuch der Webseite - in Ihrem Browser werden <strong>KEINE</strong>{" "}
        Cookies gesetzt, die zur Identifizierung dienen könnten.
      </p>

      <h2>2. Zweck der Datenverarbeitung</h2>
      <p>
        Ich verwende die von Ihnen aktiv mitgeteilten personenbezogenen Daten
        nur für den jeweils vereinbarten Zweck und nur im erforderlichen Umfang.
      </p>

      <p>
        Daten, die Sie mir im Zusammenhang mit einer Kontaktanfrage mitteilen,
        werden nur für die Bearbeitung der Anfrage verarbeitet. Rechtsgrundlage
        der Verarbeitung ist ab dem 25. Mai 2018 Art. 6 (1) b) DSGVO. Dazu
        verwenden wir <a href="https://formspree.io"> https://formspree.io</a> –
        es gelten deren Datenschutzbestimmungen:
        <a href="https://formspree.io/legal/privacy-policy">
          https://formspree.io/legal/privacy-policy{" "}
        </a>
      </p>

      <h2>3. Dauer, für die die personenbezogenen Daten gespeichert werden </h2>

      <p>
        Personenbezogenen Daten, die mir im Zusammenhang mit einer
        Kontaktanfrage auf unserer Webseite übermittelt werden, werden nur für
        die Dauer der Bearbeitung der Anfrage gespeichert.
      </p>
      <h2>4. Recht auf Auskunft, Berichtigung, Löschung, Einschränkung</h2>
      <p>
        Im Rahmen der DSGVO stehen betroffenen Personen die folgenden Rechte zu:
        <br></br>
        <br></br>+ Recht auf Auskunft<br></br>+ Recht auf Löschung<br></br>+
        Recht auf Berichtigung<br></br>+ Recht auf Widerspruch<br></br>
        <br></br>
        Anfragen werden binnen eines Monats erfüllt. Da ich für diese
        Betroffenenrechte auch deine Identität feststellen müssen, ersuchen ich
        um Zusendung eines amtlichen Lichtbildausweises. Bei berechtigtem
        Zweifel behalte ich mir das Recht vor, die Identität persönlich, vor
        Ort, zu prüfen. Weiters ersuche ich um genaue Angaben, um Ihr Anliegen
        präzise zu erfüllen.
      </p>
      <h3>Beschwerderecht:</h3>
      <p>
        Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das
        Datenschutzrecht verstößt oder Ihre datenschutzrechtlichen Ansprüche in
        irgendeiner Weise verletzt worden sind, können Sie sich bei der
        Aufsichtsbehörde beschweren.
      </p>
      <p>
        Österreichische Datenschutzbehörde, 1080 Wien, Wickenburggasse 8,
        Telefon 01/52152-0, www.dsb.gv.at, dsb@dsb.gv.at
      </p>
      <h2>5. Provider </h2>

      <p>
        Der Websiteprovider erhebt Daten über Zugriffe auf die Seite und
        speichert diese Server-Logfiles und IP-Adressen für 30 Tage ab. Alle
        Informationen dazu finden Sie hier:{" "}
        <a href="https://www.netlify.com/gdpr-ccpa/">
          https://www.netlify.com/gdpr-ccpa/
        </a>
      </p>

      <h2>6. SSL-Verschlüsselung</h2>
      <p>
        Diese Seite nutzt aus Gründen der Sicherheit und zum Schutz der
        Übertragung vertraulicher Inhalte, wie zum Beispiel der Anfragen, die
        Sie an uns als Seitenbetreiber senden, eine SSL-Verschlüsselung. Eine
        verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile des
        Browsers von "http://" auf "https://" wechselt und an dem Schloss-Symbol
        in Ihrer Browserzeile. Wenn die SSL Verschlüsselung aktiviert ist,
        können die Daten, die Sie an uns übermitteln, nicht von Dritten
        mitgelesen werden.
      </p>

      <h2>6. Webanalyse</h2>
      <p>Ich verwende keine Webanalyse-Tools.</p>
    </Layout>
  )
}

export default privacy
